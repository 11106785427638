/**
 * This is a modified copy of @hagerty/react-components Modal component
 * https://dev.azure.com/hagerty/Hagerty/_git/hagerty-web?path=/packages/react-components/src/components/Modal/Modal.tsx
 */

import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Button, Icon } from '@hagerty/react-components';

const TAB_KEY = 'Tab';
const ESCAPE_KEY = 'Escape';
const FOCUSABLE_ELEMENTS =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

type Props = {
  /**
   * Id of the modal
   */
  id: string;

  /**
   * Accessible description of the modal
   */
  description?: ReactNode;

  /**
   * Determines if the modal is animated
   */
  isAnimated?: boolean;

  /**
   * Determines if the model is opened by default
   */
  initOpen?: boolean;

  /**
   * Function to call when the modal is opened
   */
  onOpen?: () => void;

  /**
   * Function to call when the modal is closed
   */
  onClose?: () => void;

  /**
   * Children to render in the modal
   */
  children?: ReactNode;
};

const FullScreenModal = ({ id, description, initOpen = false, onOpen, onClose, children }: Props): JSX.Element => {
  const ref = useRef();
  const toggleButton = useRef(null);
  const [focusableElements, setFocusableElements] = useState(null);
  const [isTabbing, setIsTabbing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(initOpen);

  const useSetElements = (
    id: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
    description: ReactNode,
    children: ReactNode
  ) => {
    useEffect(() => {
      toggleButton.current = document.getElementById(id);

      if (ref.current) {
        const focusableEls = ref.current.querySelectorAll(FOCUSABLE_ELEMENTS);
        setFocusableElements(focusableEls);
      }
    }, [id, ref, description, children]);
  };

  useSetElements(id, ref, description, children);

  const show = useCallback(() => {
    setModalOpen(true);
    document.body.classList.add('is-full-modal', 'is-modal-open');
    isTabbing && setTimeout(() => focusableElements[0].focus());
    onOpen?.();
  }, [focusableElements, isTabbing, onOpen]);

  const hiding = () => {
    document.body.classList.remove('is-full-modal', 'is-modal-open');
    document.body.classList.add('is-modal-closing');
    setTimeout(() => {
      document.body.classList.remove('is-modal-closing');
      setModalOpen(false);
      isTabbing && toggleButton.current?.focus();
      onClose?.();
    }, 390);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useOnClickOutside = (ref: any, handler: any) => {
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const listener = (event: any) => {
        setIsTabbing(false);

        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('click', listener);
      return () => {
        document.removeEventListener('click', listener);
      };
    }, [ref, handler]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useOnClickOutside(ref, (event: any) => {
    event.target.id == id ? show() : null;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFocusTrap = (ref: any, isModalOpen: boolean) => {
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const listener = (event: any) => {
        setIsTabbing(true);

        if (isModalOpen && event.key === ESCAPE_KEY) {
          hiding();
          toggleButton.current.focus();
        } else if (!isModalOpen || event.key !== TAB_KEY) {
          return;
        } else if (event.shiftKey && document.activeElement === focusableElements[0]) {
          // shift + tab and is first element
          event.preventDefault();
          focusableElements[focusableElements.length - 1].focus();
        } else if (
          (!event.shiftKey && document.activeElement === focusableElements[focusableElements.length - 1]) ||
          !ref.current.contains(event.target)
        ) {
          // tab and is last element or target is not a child of the modal
          event.preventDefault();
          focusableElements[0].focus();
        }
      };

      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }, [ref, isModalOpen]);
  };

  useFocusTrap(ref, isModalOpen);

  // Remove 'is-full-modal' and `is-modal-open` class on unmount
  useEffect(() => {
    return () => {
      document.body.classList.remove('is-full-modal', 'is-modal-open');
    };
  }, []);

  return (
    <div
      ref={ref}
      className="full-screen-modal modal modal_centered modal_animated"
      role="dialog"
      aria-modal="true"
      aria-hidden="false"
      style={isModalOpen ? { display: 'block' } : { display: 'none' }}
      data-testid="modal-trigger"
    >
      {description && <div className="sr-only">{description}</div>}

      <div className="full-screen-modal__container container container_center">
        <Button
          aria-label="Close"
          onClick={hiding}
          className="button button-close button-close_large modal__close-button"
          testId="modal-close-btn"
        >
          <Icon icon="CloseX24" color="dark" size="large" />
        </Button>

        <div>{children}</div>
      </div>
    </div>
  );
};

export { FullScreenModal };
